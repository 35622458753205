<template>
  <div class="home">
    <!-- HERO section -->
    <section
        class="mt-8 mb-20 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24"
    >
      <div class="lg:grid lg:grid-cols-12 lg:gap-8">
        <div
            class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left"
        >
          <h2
              class="text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-4xl sm:leading-none md:text-5xl"
          >
            {{ $t('page.home.header.title.one') }}
            <br class="xl:hidden"/>
            <span class="text-tawny-port-600">{{ $t('page.home.header.title.two') }}</span>
          </h2>
          <p
              class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
          >
            {{ $t('page.home.header.description') }}
          </p>
          <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div class="rounded-md shadow">
              <router-link to="/contact"
                           class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-tawny-port-600 hover:bg-tawny-port-600 focus:outline-none transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
              >
                {{ $t('button.get_started') }}
              </router-link>
            </div>
            <!--            <div class="mt-3 sm:mt-0 sm:ml-3">-->
            <!--              <router-link to="/contact"-->
            <!--                  [routerLink]="[contact]"-->
            <!--                  class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"-->
            <!--              >-->
            <!--                Live demo-->
            <!--              </rout>-->
            <!--            </div>-->
          </div>
        </div>

        <!-- Mobile -->
        <div
            class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center"
        >
          <svg
              class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
              width="640"
              height="784"
              fill="none"
              viewBox="0 0 640 784"
          >
            <defs>
              <pattern
                  id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
                  x="118"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
              >
                <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-gray-200"
                    fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
                y="72"
                width="400"
                height="400"
                class="text-gray-50"
                fill="currentColor"
            />
            <rect
                x="118"
                width="450"
                height="450"
                fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"
            />
          </svg>
          <div class="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
            <button
                type="button"
                class="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline"
            >
              <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6751923399951429632?compact=1"
                      height="284" width="504" frameborder="0" allowfullscreen="" class="w-full"
                      title="linkedIn Beyond Selection"></iframe>

            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- What is Beyond Selection -->
    <section
        class="max-w-screen-xl mx-auto px-4 pt-16 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24"
    >
      <div
          class="bg-indigo-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4"
      >
        <div
            class="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20"
        >
          <div class="lg:self-center">
            <h2
                class="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10"
            >
          <span class="block"
          >
            {{ $t('page.home.cta.title') }}</span
          >
            </h2>
            <p class="mt-4 text-lg leading-6 text-indigo-200">
              {{ $t('page.home.cta.body.first') }}
              <br/><br/>
              {{ $t('page.home.cta.body.second') }}
              <br/><br/>
              {{ $t('page.home.cta.body.third') }}
            </p>
            <router-link to="/about"
                         class="mt-8 bg-white border border-transparent rounded-md shadow px-6 py-3 inline-flex items-center text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 hover:bg-gray-50 transition duration-150 ease-in-out"
            >{{ $t('button.learn_more') }}
            </router-link>
          </div>
        </div>
        <div class="relative pb-3/5 -mt-6 md:pb-1/2">
          <img
              class="absolute inset-0 w-full h-full transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
              :src="require(`@/assets/img/product.png`)"
              alt="App screenshot"
          />
        </div>
      </div>
    </section>

    <!-- USP -->
    <section class="overflow-hidden">
      <div
          class="relative max-w-screen-xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-28"
      >
        <svg
            class="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
            width="404"
            height="784"
            fill="none"
            viewBox="0 0 404 784"
        >
          <defs>
            <pattern
                id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
            >
              <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
              width="404"
              height="784"
              fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
          />
        </svg>

        <div class="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div class="lg:col-span-1">
            <h3
                class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
            >
              {{ $t('page.home.usp.title') }}
            </h3>
          </div>
          <div
              class="mt-10 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:col-span-2 lg:mt-0"
          >
            <div>
              <div
                  class="flex items-center justify-center h-12 w-12 rounded-md bg-tawny-port-500 text-white"
              >
                <LightningBoltIcon class="h-6 w-6"/>
              </div>
              <div class="mt-5">
                <h4 class="text-lg leading-6 font-medium text-gray-900">
                  {{ $t('page.home.usp.one.name') }}
                </h4>
                <p class="mt-2 text-base leading-6 text-gray-500">
                  {{ $t('page.home.usp.one.description') }}
                </p>
              </div>
            </div>
            <div class="mt-10 sm:mt-0">
              <div
                  class="flex items-center justify-center h-12 w-12 rounded-md bg-tawny-port-500 text-white"
              >
                <ScaleIcon class="h-6 w-6"/>
              </div>
              <div class="mt-5">
                <h4 class="text-lg leading-6 font-medium text-gray-900">
                  {{ $t('page.home.usp.two.name') }}
                </h4>
                <p class="mt-2 text-base leading-6 text-gray-500">
                  {{ $t('page.home.usp.two.description') }}
                </p>
              </div>
            </div>
            <div class="mt-10 sm:mt-0">
              <div
                  class="flex items-center justify-center h-12 w-12 rounded-md bg-tawny-port-500 text-white"
              >
                <!-- Heroicon name: globe-alt -->
                <ZoomInIcon class="h-6 w-6"/>
              </div>
              <div class="mt-5">
                <h4 class="text-lg leading-6 font-medium text-gray-900">
                  {{ $t('page.home.usp.three.name') }}
                </h4>
                <p class="mt-2 text-base leading-6 text-gray-500">
                  {{ $t('page.home.usp.three.description') }}
                </p>
              </div>
            </div>
            <div class="mt-10 sm:mt-0">
              <div
                  class="flex items-center justify-center h-12 w-12 rounded-md bg-tawny-port-500 text-white"
              >
                <AdjustmentsIcon class="h-6 w-6" />
              </div>
              <div class="mt-5">
                <h4 class="text-lg leading-6 font-medium text-gray-900">
                  {{ $t('page.home.usp.four.name') }}
                </h4>
                <p class="mt-2 text-base leading-6 text-gray-500">
                  {{ $t('page.home.usp.four.description') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- company logo -->
    <section class="bg-indigo-700">
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div class="lg:grid lg:grid-cols-2 lg:gap-8">
          <h2
              class="max-w-md mx-auto text-3xl leading-9 font-extrabold text-white text-center lg:max-w-xl lg:text-left"
          >
            {{ $t('page.home.logo_cloud.title') }}
          </h2>
          <div class="flow-root self-center mt-8 lg:mt-0">
            <div class="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
              <div
                  class="mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4"
              >
                <img
                    class="h-12"
                    src="https://goud-it.nl/_next/static/media/logo-2.646d9b46.webp"
                    alt="Goud-IT"
                />
              </div>
              <div
                  class="mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4"
              >
                <img class="h-16"
                     :src="require(`@/assets/img/odk-min.png`)" alt="Odk webdesign"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--    contact-->
    <section class="bg-gray-50">
      <div class="px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-10">
        <div
            class="relative max-w-3xl mx-auto rounded bg-indigo-700 my-16 px-16 py-16"
        >
          <div class="text-center">
            <h2
                class="text-3xl leading-9 font-extrabold tracking-tight text-white sm:text-4xl sm:leading-10"
            >
              {{ $t('page.home.contact.title') }}
            </h2>
            <p class="mt-4 text-lg leading-6 text-white">
              {{ $t('page.home.contact.description') }}
            </p>
          </div>
          <div class="mt-12">
            <div class="mt-8">
              <div class="rounded-lg shadow-md">
                <router-link to="/contact"
                             class="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                             aria-describedby="tier-scale"
                >
                  {{ $t('button.contact') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import {AdjustmentsIcon, ScaleIcon, LightningBoltIcon, ZoomInIcon} from '@heroicons/vue/outline'

export default {
  name: 'Home',
  components: {
    AdjustmentsIcon,
    ScaleIcon,
    LightningBoltIcon,
    ZoomInIcon
  },
  data() {
    return {};
  },
}
</script>
